import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"

export function withoutToast(Component): ComponentType {
    return (props) => {
        const copyEmail = () => {
            const email = "hi@mattahrens.design"
            navigator.clipboard.writeText(email)
        }
        return <Component {...props} onTap={copyEmail} />
    }
}
